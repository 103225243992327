<div class="hero-wrapper hero-1">
    <div class="hero-bg-gradient"></div>
    <div class="ripple-shape">
        <span class="ripple-1"></span>
        <span class="ripple-2"></span>
        <span class="ripple-3"></span>
        <span class="ripple-4"></span>
        <span class="ripple-5"></span>
    </div>

    <div class="container">
        <div class="hero-style1">
            <div class="row flex-row-reverse">
                <div class="col-lg-3">
                    <div class="hero-thumb alltuchtopdown">
                        <img src="/assets/img/update/hero/hero-1-1.jpg" alt="img" />
                    </div>
                </div>
                <div class="col-lg-9">
                    <h1 class="hero-title">
                        Now your <span class="special-letter">Girlfriend</span> has everything you like <br />
                        <span class="special-letter">AI</span> - <span class="special-letter">Blockchain</span> - <span class="special-letter">Metaverse</span>
                    </h1>
                    <div class="btn-wrap">
                        <a routerLink="/contact" class="btn btn2"> Purchase a Token </a>
                        <a routerLink="/blog" class="btn btn-two"> Read Documents </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-countdown-wrap">
            <h2 class="hero-countdown-wrap-title">Where we are...</h2>
            <ul class="skill-feature_list">
                <li>APP released</li>
                <li><span>20k downloads</span> milestone</li>
                <li>
                    <h4>Pump.fun</h4>
                    <h4>Mirai</h4>
                    <h4>token launch</h4>
                </li>
            </ul>
            <div class="skill-feature">
                <div class="progress">
                    <div class="progress-bar" style="width: 80%"></div>
                </div>
                <div class="progress-value-max">More to come...</div>
            </div>
            <ul class="skill-feature_list style2">
                <li>Q3 2023</li>
                <li>Q1 2024</li>
                <li>Q3 2024</li>
            </ul>
            <div class="banner-countdown-wrap">
                Comming soon...
                <!-- countdown timer -->
                <!-- <app-countdown-timer [date]="'2024/7/15'"></app-countdown-timer> -->
                <!-- countdown timer  -->
            </div>
        </div>
    </div>
</div>

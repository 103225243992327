import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogSidebarComponent } from '../../shared/components/blog/blog-sidebar/blog-sidebar.component';
import { BlogSingleComponent } from '../../shared/components/blog/blog-single/blog-single.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { DocumentAreaComponent } from '../../shared/components/document-area/document-area.component';
import { PaginationComponent } from '../../shared/components/pagination/pagination.component';
import { FooterThreeComponent } from '../../shared/layout/footer/footer-three/footer-three.component';
import { HeaderThreeComponent } from '../../shared/layout/header/header-three/header-three.component';
import { BlogApiService } from '../../shared/services/api/apis/blog.service';
import { PaginationService } from '../../shared/services/pagination.service';
import { UtilsService } from '../../shared/services/utils.service';
import { PostBlog } from '../../shared/types/blog-d-t';

@Component({
    selector: 'app-blog',
    standalone: true,
    imports: [CommonModule, HeaderThreeComponent, BreadcrumbComponent, DocumentAreaComponent, FooterThreeComponent, BlogSingleComponent, BlogSidebarComponent, PaginationComponent],
    templateUrl: './blog.component.html',
    styleUrl: './blog.component.css',
})
export class BlogComponent {
    public blogs: PostBlog[] = [];
    public pageSize: number = 6;
    public paginate: any = {};
    public pageNo: number = 1;
    private topic: string = 'crypto';

    constructor(public paginationService: PaginationService, public utilsService: UtilsService, private route: ActivatedRoute, private router: Router, private blogApiService: BlogApiService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.pageNo = params['page'] ? params['page'] : this.pageNo;

            this.blogApiService.getPostsBlogApi(this.topic).subscribe((response) => {
                if (Array.isArray(response)) {
                    this.blogs = response;
                } else if (typeof response === 'object') {
                    this.blogs = [response];
                } else {
                    console.error('Unexpected response type:', response);
                }

                this.blogs = this.blogs
                    .map((blog: any) => {
                        blog.date = this.formatDate(blog.date);
                        return blog;
                    })
                    .sort((a, b) => {
                        return new Date(b.date).getTime() - new Date(a.date).getTime();
                    });

                this.paginate = this.paginationService.getPager(this.blogs.length, Number(this.pageNo), this.pageSize);
                this.blogs = this.blogs.slice(this.paginate.startIndex, this.paginate.endIndex + 1);
            });
        });
    }

    formatDate(dateString: string): string {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    }

    setPage(page: number) {
        this.router
            .navigate([], {
                relativeTo: this.route,
                queryParams: { page: page },
                queryParamsHandling: 'merge',
                skipLocationChange: false,
            })
            .finally(() => {
                window.scrollTo(0, 0);
            });
    }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { inject } from '@vercel/analytics';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter } from 'rxjs/operators';
import { BackToTopComponent } from './shared/components/back-to-top/back-to-top.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, BackToTopComponent],
    templateUrl: './app.component.html',
})
export class AppComponent {
    title = 'Mirai - Token';

    constructor(private router: Router, private $gaService: GoogleAnalyticsService) {
        inject();
    }

    ngOnInit() {
        this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.$gaService.pageView(event.urlAfterRedirects);
        });
    }
}

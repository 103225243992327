<div class="brand-area2">
    <div class="container">
        <div class="row g-0">
            <div class="col-lg-12">
                <div class="brand-title text-center">
                    <h6 class="title">Our top Integrations</h6>
                </div>
            </div>
        </div>
        <div class="brand-item-wrap style2">
            <div class="row g-0 brand-active2 swiper">
                <div class="swiper-wrapper p-0">
                    <div *ngFor="let b of brands" class="col-12 swiper-slide">
                        <div class="brand-item">
                            <img [src]="b" alt="brand-logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

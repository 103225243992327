<footer class="footer-wrapper footer-layout2 pb-50">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-auto col-lg-6 order-xl-1">
                <div class="widget footer-widget">
                    <div class="widget-about">
                        <div class="footer-logo">
                            <a routerLink="/home">
                                <img src="/assets/img/logo/logo.png" style="height: 35px" alt="Mirai" />
                            </a>
                        </div>
                        <p class="about-text">Mirai is a pioneering technology company specializing in the integration of advanced AI and blockchain solutions to revolutionize virtual relationships.</p>
                        <div class="social-btn style2">
                            <a href="https://t.me/mirai_coin_official_channel" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-auto col-lg-6 order-xl-3">
                <div class="footer-widget widget-newsletter">
                    <h3 class="fw-title">SIGN UP FOR EMAIL UPDATES</h3>
                    <p class="newsletter-text">Sign up with your email address to receive news and updates</p>
                    <form class="newsletter-form">
                        <div class="form-group">
                            <input class="form-control" type="email" placeholder="Your Email Address" required="" />
                        </div>
                        <button type="submit" class="btn btn5">Subscribe</button>
                    </form>
                </div>
            </div>
            <div class="col-xl-auto col-lg-6 order-xl-2">
                <div class="footer-widget widget-contact">
                    <h3 class="fw-title">CONTACT US</h3>
                    <!-- <p class="contact-info-text">-</p> -->
                    <!-- <div class="contact-info-link">Call Us: <a href="tel:8002758777">-</a></div> -->
                    <div class="contact-info-link"><a href="mailto:contact@mirailab.io">contact&#64;mirailab.io</a></div>
                    <p class="copyright-text">Copyright © {{ date }} <a href="#">Mirai.</a> All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-why-choose-one',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './why-choose-one.component.html',
    styleUrl: './why-choose-one.component.css',
})
export class WhyChooseOneComponent {
    public progress_items = [
        {
            title: 'Expected Mirai price',
            subtitle: '0.36 $',
            width: 70,
            value: {
                min: '100 $',
                max: '100,000 $',
            },
        },
        {
            title: 'Expected Mirai price',
            subtitle: '0.36 $',
            width: 80,
            value: {
                min: '100 $',
                max: '100,000 $',
            },
        },
        {
            title: 'Calculation time',
            subtitle: 'Q3 2024',
            width: 40,
            value: {
                min: '100 $',
                max: '100,000 $',
            },
        },
    ];

    public feature_data = [
        {
            icon: '/assets/img/update/icon/feature-icon1-1.svg',
            title: 'The expected value of your investment',
            text: '180,000 $',
            text2: 'ROI = 360 %',
        },
        {
            icon: '/assets/img/update/icon/feature-icon1-2.svg',
            title: 'Expected monthly dividend',
            text: '3600 Mirai = 1296 $',
        },
        {
            icon: '/assets/img/update/icon/feature-icon1-3.svg',
            title: 'Masternode bonus',
            text: '180,000 $',
            text2: 'ROI = 360 %',
        },
    ];
}

import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, inject } from '@angular/core';
import Swiper from 'swiper';

@Component({
    selector: 'app-brand-one',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './brand-one.component.html',
    styleUrl: './brand-one.component.css',
})
export class BrandOneComponent {
    private readonly platformId = inject(PLATFORM_ID);

    public brands = ['/assets/img/brand/solana.png', '/assets/img/brand/telegram.png', '/assets/img/brand/angular.png', '/assets/img/brand/discord.png', '/assets/img/brand/openai.png'];

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            new Swiper('.brand-active2', {
                slidesPerView: 5,
                spaceBetween: 0,
                loop: false,
                breakpoints: {
                    '1200': {
                        slidesPerView: 5,
                    },
                    '992': {
                        slidesPerView: 4,
                    },
                    '768': {
                        slidesPerView: 3,
                    },
                    '576': {
                        slidesPerView: 2,
                    },
                    '0': {
                        slidesPerView: 2,
                    },
                },
            });
        }
    }
}

<header id="header">
    <div id="sticky-header" class="menu-area transparent-header" [class.sticky-menu]="sticky">
        <div class="container custom-container">
            <div class="row">
                <div class="col-12">
                    <div class="menu-wrap">
                        <nav class="menu-nav">
                            <div class="logo">
                                <a routerLink="/home">
                                    <img src="/assets/img/logo/logo.png" style="height: 35px" alt="Logo" />
                                </a>
                            </div>
                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                                <ul class="navigation">
                                    <li class="menu-item-has-children">
                                        <a routerLink="/home">Home</a>
                                    </li>
                                    <li [class.active]="isFeatureRouteActive()">
                                        <a class="section-link pointer" (click)="navigateWithOffset('feature')"> Feature </a>
                                    </li>
                                    <li [class.active]="isRoadMapRouteActive()">
                                        <a class="section-link pointer" (click)="navigateWithOffset('roadMap')"> RoadMap </a>
                                    </li>
                                    <li class="menu-item-has-children" [routerLinkActive]="['active']">
                                        <a routerLink="/blog">blog</a>
                                    </li>
                                    <li [routerLinkActive]="['active']">
                                        <a routerLink="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="header-action">
                                <ul class="list-wrap">
                                    <li class="header-login">
                                        <a class="btn2" href="https://x.com/MirAiToken" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                                    </li>
                                    <li class="header-login">
                                        <a class="btn2" href="https://t.me/mirai_coin_official_channel" target="_blank"><i class="fab fa-telegram-plane"></i></a>
                                    </li>
                                    <li class="header-login">
                                        <a class="btn2" href="https://blog.mirailab.io" target="_blank">Web/APP</a>
                                    </li>

                                    <li class="offcanvas-menu">
                                        <a (click)="utilsService.openOffcanvas = !utilsService.openOffcanvas" class="menu-tigger pointer">
                                            <i class="fas fa-bars"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mobile-nav-toggler" (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus">
                                <i class="fas fa-bars"></i>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- offCanvas-menu -->
    <div class="extra-info" [class.active]="utilsService.openOffcanvas">
        <div class="close-icon menu-close">
            <button (click)="utilsService.openOffcanvas = !utilsService.openOffcanvas">
                <i class="far fa-window-close"></i>
            </button>
        </div>
        <div class="logo-side mb-30">
            <a routerLink="/home">
                <img src="/assets/img/logo/logo.png" style="height: 35px" alt="Logo" />
            </a>
        </div>
        <div class="side-info mb-30">
            <!-- <div class="contact-list mb-30">
                <h4>Office Address</h4>
                <p>
                    123/A, Miranda City Likaoli <br />
                    Prikano, Dope
                </p>
            </div> -->
            <!-- <div class="contact-list mb-30">
                <h4>Phone Number</h4>
                <p>+0989 7876 9865 9</p>
                <p>+(090) 8765 86543 85</p> 
            </div> -->
            <div class="contact-list mb-30">
                <h4>Email Address</h4>
                <p>contact&#64;mirailab.io</p>
            </div>
        </div>
        <div class="social-icon-right mt-30">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </a>
        </div>
    </div>
    <div class="offcanvas-overly" [class.active]="utilsService.openOffcanvas" (click)="utilsService.openOffcanvas = !utilsService.openOffcanvas"></div>
    <!-- offCanvas-menu-end -->

    <!-- mobile offcanvas menu -->
    <app-mobile-offcanvas />
    <!-- mobile offcanvas menu -->
</header>

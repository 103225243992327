<aside class="blog-sidebar">
    <!-- <div class="blog-widget">
        <h4 class="widget-title">Search</h4>
        <div class="sidebar-search">
            <form action="#">
                <input type="text" placeholder="Search your keyword" />
                <button type="submit"><i class="fas fa-search"></i></button>
            </form>
        </div>
    </div>
    <div class="blog-widget">
        <h4 class="widget-title">Categories</h4>
        <div class="sidebar-cat-list">
            <ul class="list-wrap">
                <li>
                    <a href="#">Blockchain <span>05</span></a>
                </li>
                <li>
                    <a href="#">Web Development <span>03</span></a>
                </li>
                <li>
                    <a href="#">Cryptocurrency <span>06</span></a>
                </li>
                <li>
                    <a href="#">Branding Design <span>05</span></a>
                </li>
                <li>
                    <a href="#">Uncategorized <span>02</span></a>
                </li>
            </ul>
        </div>
    </div> -->
    <div class="blog-widget">
        <h4 class="widget-title">Recent Posts</h4>
        <div class="rc-post-wrap">
            <div *ngFor="let rc of recent_posts" class="rc-post-item">
                <div class="thumb">
                    <a [routerLink]="'/blog-details/' + rc.id">
                        <img [src]="rc.thumbnail" alt="" style="width: 80px; height: 80px; object-fit: cover" />
                    </a>
                </div>
                <div class="content">
                    <span class="date">{{ rc.date }}</span>
                    <h6 class="title">
                        <a [routerLink]="'/blog-details/' + rc.id">{{ rc.title.slice(0, 40) }}..</a>
                    </h6>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-widget">
        <h4 class="widget-title">Follow Us</h4>
        <div class="sidebar-follow-wrap">
            <ul class="list-wrap">
                <li>
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </a>
                </li>
            </ul>
            <a routerLink="/contact" class="btn">Get In Touch</a>
        </div>
    </div>
    <!-- <div class="blog-widget">
        <h4 class="widget-title">Tags</h4>
        <div class="sidebar-tag-list">
            <ul class="list-wrap">
                <li><a href="#">Mirai</a></li>
                <li><a href="#">blockchain</a></li>
                <li><a href="#">investments</a></li>
                <li><a href="#">currency</a></li>
                <li><a href="#">crypto trading</a></li>
                <li><a href="#">crypto</a></li>
                <li><a href="#">Mirai blockchain</a></li>
                <li><a href="#">advisor</a></li>
                <li><a href="#">web</a></li>
                <li><a href="#">Mirai Tokens</a></li>
            </ul>
        </div>
    </div> -->
</aside>

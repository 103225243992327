import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BlogApiService } from './shared/services/api/apis/blog.service';
import { PostBlog } from './shared/types/blog-d-t';

@Injectable({
    providedIn: 'root',
})
export class BlogDetailsResolver implements Resolve<PostBlog | null> {
    private topic: string = 'crypto';

    constructor(private blogApiService: BlogApiService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PostBlog | null> {
        const id = route.paramMap.get('id');
        if (id) {
            return this.blogApiService.getPostById(id, this.topic).pipe(catchError(() => of(null)));
        } else {
            return of(null);
        }
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-two',
    standalone: true,
    imports: [],
    templateUrl: './footer-two.component.html',
    styleUrl: './footer-two.component.css',
})
export class FooterTwoComponent {
    date = new Date().getFullYear();
}

<div class="wcu-area-1 pt-130 pb-140 position-relative" id="feature">
    <div class="bg-gradient-1">
        <img src="/assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
    </div>
    <div class="container">
        <div class="mb-25">
            <div class="row gy-5">
                <div class="col-lg-7">
                    <div class="section-title mb-0">
                        <h2 class="title style2">Why Choose Mirai Tokens Today?</h2>
                        <p class="sec-text">
                            Investing in Mirai Tokens now offers you the opportunity to be part of a revolutionary technology that enhances virtual relationships through AI and blockchain. <br />
                            Calculate the potential growth of your Mirai Tokens and see how your investment can generate monthly rewards over time.
                        </p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="wcu-thumb text-center alltuchtopdown">
                        <img src="/assets/img/update/normal/why_1-1.png" alt="img" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row gy-5 justify-content-between">
            <div class="col-lg-5">
                <div class="wcu-amount-quantity">
                    <div class="amount">
                        <h5 class="title">Amount invested</h5>
                        <p class="price">50,000 $</p>
                    </div>
                    <div class="quantity">
                        <h5 class="title">Quantity Mirai Tokens</h5>
                        <p class="price">500,000 Mirai</p>
                    </div>
                </div>
                <ul class="wcu-price-progress-wrap">
                    <li *ngFor="let p of progress_items">
                        <h6 class="progress-wrap-title">{{ p.title }}</h6>
                        <p class="progress-wrap-text">{{ p.subtitle }}</p>
                        <div class="skill-feature">
                            <div class="progress">
                                <div class="progress-bar" [ngStyle]="{ width: p.width + '%' }"></div>
                            </div>
                            <div class="progress-value">
                                <span>{{ p.value.min }}</span>
                                <span>{{ p.value.max }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <div *ngFor="let f of feature_data" class="feature-card">
                    <div class="feature-card-icon">
                        <img [src]="f.icon" alt="img" />
                    </div>
                    <div class="feature-card-details">
                        <h4 class="feature-card-title">{{ f.title }}</h4>
                        <p class="feature-card-text">{{ f.text }}</p>
                        <p *ngIf="f.text2" class="feature-card-text">{{ f.text2 }}</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

import { Component } from '@angular/core';

@Component({
    selector: 'app-feature-one',
    standalone: true,
    imports: [],
    templateUrl: './feature-one.component.html',
    styleUrl: './feature-one.component.css',
})
export class FeatureOneComponent {}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BrandOneComponent } from '../../../shared/components/brand/brand-one/brand-one.component';
import { BrandTwoComponent } from '../../../shared/components/brand/brand-two/brand-two.component';
import { CtaOneComponent } from '../../../shared/components/cta/cta-one/cta-one.component';
import { CtaTwoComponent } from '../../../shared/components/cta/cta-two/cta-two.component';
import { EventsComponent } from '../../../shared/components/events/events.component';
import { FaqOneComponent } from '../../../shared/components/faq/faq-one/faq-one.component';
import { FaqTwoComponent } from '../../../shared/components/faq/faq-two/faq-two.component';
import { FeatureOneComponent } from '../../../shared/components/feature/feature-one/feature-one.component';
import { FeatureThreeComponent } from '../../../shared/components/feature/feature-three/feature-three.component';
import { FeatureTwoComponent } from '../../../shared/components/feature/feature-two/feature-two.component';
import { HeroOneComponent } from '../../../shared/components/hero/hero-one/hero-one.component';
import { HeroTwoComponent } from '../../../shared/components/hero/hero-two/hero-two.component';
import { IntroAreaOneComponent } from '../../../shared/components/intro/intro-area-one/intro-area-one.component';
import { InvestAreaComponent } from '../../../shared/components/invest-area/invest-area.component';
import { PartnersComponent } from '../../../shared/components/partners/partners.component';
import { RoadmapOneComponent } from '../../../shared/components/roadmap/roadmap-one/roadmap-one.component';
import { TeamAreaComponent } from '../../../shared/components/team-area/team-area.component';
import { TestimonialComponent } from '../../../shared/components/testimonial/testimonial.component';
import { WhyChooseOneComponent } from '../../../shared/components/why-choose/why-choose-one/why-choose-one.component';
import { FooterOneComponent } from '../../../shared/layout/footer/footer-one/footer-one.component';
import { FooterTwoComponent } from '../../../shared/layout/footer/footer-two/footer-two.component';
import { HeaderOneComponent } from '../../../shared/layout/header/header-one/header-one.component';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
    selector: 'app-home-main',
    standalone: true,
    imports: [
        HeaderOneComponent,
        HeroOneComponent,
        BrandOneComponent,
        WhyChooseOneComponent,
        IntroAreaOneComponent,
        RoadmapOneComponent,
        InvestAreaComponent,
        TeamAreaComponent,
        PartnersComponent,
        EventsComponent,
        FaqOneComponent,
        FooterOneComponent,
        CommonModule,
        HeroTwoComponent,
        BrandTwoComponent,
        FeatureOneComponent,
        FeatureTwoComponent,
        FeatureThreeComponent,
        TestimonialComponent,
        CtaOneComponent,
        FaqTwoComponent,
        CtaTwoComponent,
        FooterTwoComponent,
    ],
    templateUrl: './home-main.component.html',
    styleUrl: './home-main.component.css',
})
export class HomeMainComponent {
    constructor(public utilsService: UtilsService) {
        console.log('HomeMainComponent');
    }
}

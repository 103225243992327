import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import Swiper from 'swiper';

@Component({
    selector: 'app-roadmap-one',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './roadmap-one.component.html',
    styleUrls: ['./roadmap-one.component.css'],
})
export class RoadmapOneComponent implements OnInit {
    private readonly platformId = inject(PLATFORM_ID);

    public roadmap_items = [
        {
            subtitle: 'Q2 2023',
            title: 'Launch App Android',
            desc: '-----',
        },
        {
            subtitle: 'Q3 2023',
            title: 'Add various avatars',
            desc: '-----',
        },
        {
            subtitle: 'Q4 2023',
            title: 'Add purchases and tokens coins',
            desc: '-----',
        },
        {
            subtitle: 'Q1 2024',
            title: 'Add sound messages and vision images',
            desc: '-----',
        },
        {
            subtitle: 'Q2 2024',
            title: 'Add iOS App',
            desc: '-----',
        },
        {
            subtitle: 'Q3 2024',
            title: 'Remake the design of the application',
            desc: '-----',
        },
        {
            subtitle: 'Q4 2024',
            title: 'Add token exchange',
            desc: '-----',
        },
        {
            subtitle: 'Q1 2025',
            title: 'Customize avatars realistics',
            desc: '-----',
        },
        {
            subtitle: 'Q2 2025',
            title: 'Metaverse integration',
            desc: '-----',
        },
        {
            subtitle: 'Q3 2025',
            title: 'Connect with others apps as an assistant',
            desc: '-----',
        },
        {
            subtitle: 'Q4 2025',
            title: 'Videocalls realtimes',
            desc: '-----',
        },
        {
            subtitle: 'Q1 2026',
            title: 'Launch pyshics devices for interaction with the app',
            desc: '-----',
        },
        {
            subtitle: '2027',
            title: 'First proub of concept of the first AI with a real avatar with real head.',
            desc: '-----',
        },
    ];

    public swiperInstance: Swiper | undefined;

    constructor() {}

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.swiperInstance = new Swiper('.roadMap-active2', {
                slidesPerView: 4,
                spaceBetween: 0,
                loop: true,
                breakpoints: {
                    '1400': {
                        slidesPerView: 4,
                    },
                    '1200': {
                        slidesPerView: 3,
                    },
                    '992': {
                        slidesPerView: 3,
                    },
                    '768': {
                        slidesPerView: 2,
                    },
                    '576': {
                        slidesPerView: 1,
                    },
                    '0': {
                        slidesPerView: 1,
                    },
                },
            });
        }
    }
}

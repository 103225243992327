<div [class.mobile-menu-visible]="utilsService.openMobileMenus">
    <!-- header area -->
    <app-header-three />
    <!-- header area -->

    <main>
        <!-- breadcrumb area -->
        <app-breadcrumb title="Blog Details" subTitle="Blog Details" />
        <!-- breadcrumb area -->

        <!-- blog-details-area -->
        <section class="blog-details-area pt-140 pb-140">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="blog-details-wrap">
                            <div class="blog-details-thumb">
                                <img [src]="blog?.thumbnail" alt="" class="w-100" />
                            </div>
                            <div class="blog-details-content">
                                <div class="blog-meta">
                                    <ul class="list-wrap">
                                        <li class="blog-author">
                                            <a routerLink="/blog">
                                                <img [src]="blog?.author" alt="" />
                                                {{ blog?.author }}
                                            </a>
                                        </li>
                                        <li><i class="far fa-clock"></i>{{ blog?.date }}</li>
                                        <!-- <li>
                                            <a routerLink="/blog">
                                                <i class="far fa-comment"></i>
                                                {{ blog?.comments }}
                                            </a>
                                        </li> -->
                                        <!-- <li><i class="far fa-eye"></i>{{ blog?.view }}Viewers</li> -->
                                    </ul>
                                </div>
                                <h2 class="title">{{ blog?.title }}</h2>
                                <div [innerHTML]="blog?.content"></div>
                            </div>
                        </div>
                        <!-- <div class="blog-avatar-wrap mb-65">
                            <div class="blog-avatar-img">
                                <a href="#"><img src="/assets/img/blog/avatar.png" alt="img" /></a>
                            </div>
                            <div class="blog-avatar-info">
                                <h4 class="name"><a href="#">About Anik Singal</a></h4>
                                <p>The Founder and Creative Director of Mirai, a digital creative studio in USA, has over 15 years of experience as an award-winning Creative Director/Art for clients.</p>
                            </div>
                        </div> -->
                        <!-- <div class="comments-wrap">
                            <h3 class="comments-wrap-title">Comments 02</h3>
                            <div class="latest-comments">
                                <ul class="list-wrap">
                                    <li>
                                        <div class="comments-box">
                                            <div class="comments-avatar">
                                                <img src="/assets/img/blog/comment01.png" alt="img" />
                                            </div>
                                            <div class="comments-text">
                                                <div class="avatar-name">
                                                    <h6 class="name">Reed Floren <a href="#" class="reply-btn">Reply</a></h6>
                                                    <span class="date">August 13, 2024</span>
                                                </div>
                                                <p>The platform itself was incredibly user-friendly, making it easy for me to participate in the Mirai. The intuitive interface guided me through the token purchase process seamlessly.</p>
                                            </div>
                                        </div>
                                        <ul class="children list-wrap">
                                            <li>
                                                <div class="comments-box">
                                                    <div class="comments-avatar">
                                                        <img src="/assets/img/blog/comment02.png" alt="img" />
                                                    </div>
                                                    <div class="comments-text">
                                                        <div class="avatar-name">
                                                            <h6 class="name">Tony Dargis <a href="#" class="reply-btn">Reply</a></h6>
                                                            <span class="date">August 15, 2024</span>
                                                        </div>
                                                        <p>What stood out to me the most was the exceptional customer support. The team behind the platform was responsive and helpful, promptly addressing my inquiries and providing.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form action="#" class="comment-form">
                                <p class="comment-notes">Your email address will not be published. Required fields are marked *</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-grp">
                                            <label for="name">Name</label>
                                            <input type="text" id="name" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-grp">
                                            <label for="email">Email</label>
                                            <input type="email" id="email" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-grp">
                                    <label for="website">Website</label>
                                    <input type="url" id="website" />
                                </div>
                                <div class="form-grp">
                                    <label for="message">Message</label>
                                    <textarea name="comment" id="message" placeholder="Simultaneously we had a problem"></textarea>
                                </div>
                                <div class="form-grp checkbox-grp">
                                    <input type="checkbox" class="form-check-input" id="checkbox" />
                                    <label for="checkbox">Save my name, email, and website in this browser for the next time I comment. </label>
                                </div>
                                <button type="submit" class="btn">Submit Now</button>
                            </form>
                        </div> -->
                    </div>
                    <div class="col-lg-4">
                        <app-blog-sidebar />
                    </div>
                </div>
            </div>
        </section>
        <!-- blog-details-area end -->

        <!-- document area -->
        <app-document-area />
        <!-- document area end -->
    </main>

    <!-- footer area -->
    <app-footer-three />
    <!-- footer area -->
</div>

import { Component } from '@angular/core';
import { CountdownTimerComponent } from '../../countdown-timer/countdown-timer.component';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-hero-one',
    standalone: true,
    imports: [CountdownTimerComponent, RouterModule],
    templateUrl: './hero-one.component.html',
    styleUrl: './hero-one.component.css',
})
export class HeroOneComponent {}

<div class="feature-area-2 pt-110 pb-140 position-relative overflow-hidden" style="background-image: url(assets/img/update/bg/feature-area-bg.png); background-size: cover; background-position: center" id="blockchain">
    <div class="feature-area-shape">
        <img class="feature-shape2-1 alltuchtopdown" src="/assets/img/update/feature/feature-shape-2-1.png" alt="img" />
        <img class="feature-shape2-2 alltuchtopdown" src="/assets/img/update/feature/feature-shape-2-2.png" alt="img" />
        <img class="feature-shape2-3 leftToRight" src="/assets/img/update/feature/feature-shape-2-3.png" alt="img" />
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-8">
                <div class="section-title text-center mb-50">
                    <span class="sub-title">ABOUT Mirai</span>
                    <h2 class="title style2">Why Mirai?</h2>
                    <p class="sec-text">Join the future of virtual relationships powered by AI and secured by blockchain technology. Experience unparalleled connection and security.</p>
                </div>
            </div>
        </div>
        <div class="feature-grid-wrap">
            <div class="feature-card-grid">
                <div class="feature-card-details">
                    <h3 class="feature-card-title">Flexibility</h3>
                    <p class="feature-card-text">Our platform adapts to your needs, offering customizable virtual interactions powered by AI. Enjoy a unique and personalized experience every time.</p>
                    <div class="checklist">
                        <ul>
                            <li><i class="fas fa-circle"></i> Personalized AI-driven interactions.</li>
                            <li><i class="fas fa-circle"></i> Seamless integration with your lifestyle.</li>
                            <li><i class="fas fa-circle"></i> Continuously evolving features.</li>
                        </ul>
                    </div>
                </div>
                <div class="feature-card-img">
                    <img class="alltuchtopdown" src="/assets/img/update/feature/feature-card-thumb-1.png" alt="img" />
                </div>
            </div>
            <div class="feature-card-grid">
                <div class="feature-card-details">
                    <h3 class="feature-card-title">Transparency</h3>
                    <p class="feature-card-text">Our blockchain technology ensures that all interactions are transparent and verifiable, providing peace of mind in every engagement.</p>
                    <p class="feature-card-text">Trust and clarity in every transaction.</p>
                </div>
                <div class="feature-card-img">
                    <img src="/assets/img/update/feature/feature-card-thumb-2.png" alt="img" />
                </div>
            </div>
            <div class="feature-card-grid">
                <div class="feature-card-details">
                    <h3 class="feature-card-title">Secure & Safe</h3>
                    <p class="feature-card-text">Security is our top priority. Our platform uses advanced blockchain technology to safeguard your data and ensure secure interactions.</p>
                    <p class="feature-card-text">Experience peace of mind with robust security measures.</p>
                </div>
                <div class="feature-card-img">
                    <img src="/assets/img/update/feature/feature-card-thumb-3.png" alt="img" />
                </div>
            </div>
        </div>
    </div>
</div>

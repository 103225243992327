<footer>
    <div class="footer-area footer-bg" style="background-image: url(/assets/img/bg/footer_bg.png)">
        <div class="container">
            <div class="footer-top">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <div class="footer-widget">
                            <h4 class="fw-title">Usefull Links</h4>
                            <div class="footer-link">
                                <ul class="list-wrap">
                                    <li><a routerLink="/contact">Contact us</a></li>
                                    <li><a routerLink="/contact">How it Works</a></li>
                                    <li><a routerLink="/contact">Create</a></li>
                                    <li><a routerLink="/contact">Explore</a></li>
                                    <li><a routerLink="/contact">Terms & Services</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-md-4 col-sm-6">
                        <div class="footer-widget">
                            <h4 class="fw-title">SOLUTIONS</h4>
                            <div class="footer-link">
                                <ul class="list-wrap">
                                    <li><a routerLink="/contact">Token Suite</a></li>
                                    <li><a routerLink="/contact">Ecosystem</a></li>
                                    <li><a routerLink="/contact">Investment</a></li>
                                    <li><a routerLink="/contact">Portal</a></li>
                                    <li><a routerLink="/contact">Tokenization</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                        <div class="footer-widget">
                            <h4 class="fw-title">Usefull Links</h4>
                            <div class="footer-link">
                                <ul class="list-wrap">
                                    <li><a routerLink="/contact">Help Center</a></li>
                                    <li><a routerLink="/contact">Partners</a></li>
                                    <li><a routerLink="/contact">Suggestions</a></li>
                                    <li><a routerLink="/blog">Blog</a></li>
                                    <li><a routerLink="/contact">Newsletters</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-sm-8">
                        <div class="footer-widget">
                            <h4 class="fw-title">Subscribe Newsletter</h4>
                            <div class="footer-newsletter">
                                <p>Exerci tation ullamcorper suscipit lobortis nisl aliquip ex ea commodo</p>
                                <form action="#">
                                    <input type="text" placeholder="Info@gmail.com" />
                                    <button type="submit"><i class="fas fa-paper-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="copyright-text">
                            <p>Copyright © {{ date }} Mirai. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-shape-wrap">
            <img src="/assets/img/images/footer_shape01.png" alt="" class="alltuchtopdown" />
            <img src="/assets/img/images/footer_shape02.png" alt="" class="leftToRight" />
        </div>
    </div>
</footer>

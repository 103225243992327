<div class="home-purple-gradient" [class.mobile-menu-visible]="utilsService.openMobileMenus">
    <!-- header area start -->
    <app-header-one />
    <!-- header area end -->

    <!-- hero area start -->
    <app-hero-one />
    <!-- hero area end -->

    <!-- brand area -->
    <app-brand-one />
    <!-- brand area -->

    <!-- why choose area -->
    <app-why-choose-one />
    <!-- why choose area -->

    <!-- intro area -->
    <!-- <app-intro-area-one /> -->
    <!-- intro area -->

    <!-- road map area -->
    <app-roadmap-one />
    <!-- road map area -->

    <!-- invest area -->
    <!-- <app-invest-area /> -->
    <!-- invest area -->

    <!-- cta area 2 -->
    <app-cta-two />
    <!-- cta area 2 -->

    <!-- feature area -->
    <app-feature-one />
    <!-- feature area -->

    <!-- feature area 3 -->
    <!-- <app-feature-three /> -->
    <!-- feature area 3 -->

    <!-- cta area -->
    <!-- <app-cta-one /> -->
    <!-- cta area -->

    <!-- faq rea -->
    <!-- <app-faq-one /> -->
    <!-- faq rea -->

    <!-- footer area -->
    <app-footer-two />
    <!-- footer area -->

    <!-- brand area -->
    <!-- <app-brand-two /> -->
    <!-- brand area -->

    <!-- hero area -->
    <!-- <app-hero-two /> -->
    <!-- hero area -->

    <!-- faq area -->
    <!-- <app-faq-two /> -->
    <!-- faq area -->

    <!-- footer area -->
    <!-- <app-footer-one /> -->
    <!-- footer area -->

    <!-- testimonial area -->
    <!-- <app-testimonial /> -->
    <!-- testimonial area -->

    <!-- team area -->
    <!-- <app-team-area /> -->
    <!-- team area -->

    <!-- partner area -->
    <!-- <app-partners /> -->
    <!-- partner area -->

    <!-- event area -->
    <!-- <app-events /> -->
    <!-- event area -->

    <!-- feature area 2 -->
    <!-- <app-feature-two /> -->
    <!-- feature area 2 -->
</div>

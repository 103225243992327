import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-cta-two',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './cta-two.component.html',
    styleUrl: './cta-two.component.css',
})
export class CtaTwoComponent {}

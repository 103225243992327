<div class="pt-130 pb-140 overflow-hidden bg-black2 position-relative z-index-common" id="roadMap">
    <div class="bg-gradient-2">
        <img src="/assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
    </div>
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-lg-6 col-sm-8">
                <div class="section-title mb-50">
                    <h2 class="title style2">Our Roadmap</h2>
                </div>
            </div>
            <div class="col-sm-auto">
                <div class="icon-box">
                    <button (click)="swiperInstance?.slidePrev()" class="slider-arrow prev-btn default">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <button (click)="swiperInstance?.slideNext()" class="slider-arrow next-btn default">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid p-0">
        <div class="slider-area position-relative">
            <div class="row roadMap-active2 roadmap-slider1 swiper">
                <div class="swiper-wrapper p-0">
                    <div *ngFor="let r of roadmap_items" class="col-lg-4 swiper-slide">
                        <div class="roadmap-item">
                            <span class="roadmap-title">{{ r.subtitle }}</span>
                            <div class="roadmap-content">
                                <h4 class="title"><span class="dot"></span>{{ r.title }}</h4>
                                <!-- <p>{{ r.desc }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [class.mobile-menu-visible]="utilsService.openMobileMenus">
    <!-- header area -->
    <app-header-three />
    <!-- header area -->

    <main>
        <!-- breadcrumb area -->
        <app-breadcrumb title="Our Blog" subTitle="Our Blog" />
        <!-- breadcrumb area -->

        <!-- blog-area -->
        <section class="blog-area pt-140 pb-140">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="blog-post-wrap">
                            <ng-container *ngFor="let blog of blogs">
                                <app-blog-single [blog]="blog" />
                            </ng-container>
                        </div>
                        <nav class="pagination-wrap">
                            <app-pagination [data]="blogs" [paginate]="paginate" (setPage)="setPage($event)" />
                        </nav>
                    </div>
                    <div class="col-lg-4">
                        <app-blog-sidebar />
                    </div>
                </div>
            </div>
        </section>
        <!-- blog-area-end -->

        <!-- document area -->
        <app-document-area />
        <!-- document area end -->
    </main>

    <!-- footer area -->
    <app-footer-three />
    <!-- footer area -->
</div>

<div class="pb-120 position-relative z-index-common">
    <div class="container">
        <div class="cta-wrap3">
            <div class="cta-wrap-details">
                <div class="section-title mb-20">
                    <h2 class="title style2 text-title">Penetrate the Market</h2>
                    <p class="sec-text text-title">
                        Join the funniest and sexiest memecoin in the crypto universe! Our virtual girlfriend is here to heat up your day, even when the market is cold. Hotter than Solana in the summer,<b> Mirai </b>combines solid technology with a charming smile. Join our
                        community and enjoy the spiciest romance in the world of memecoins. <br />
                        <b>Have Fun with Naughty Touches!</b>
                    </p>
                </div>

                <a routerLink="/contact" class="btn btn3"> Get started </a>
            </div>
            <div class="cta-3-thumb movingX">
                <img src="/assets/img/update/normal/cta_3-1.png" alt="img" />
            </div>
        </div>
    </div>
</div>

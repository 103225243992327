<header id="header" class="header-layout1">
    <div id="sticky-header" class="menu-area transparent-header" [class.sticky-menu]="sticky">
        <div class="container custom-container">
            <div class="row">
                <div class="col-12">
                    <div class="menu-wrap">
                        <nav class="menu-nav">
                            <div class="logo">
                                <a routerLink="/home"
                                    >Mir
                                    <img src="/assets/img/logo/logo.png" style="height: 35px" alt="Logo" />
                                    AI
                                </a>
                            </div>
                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                                <ul class="navigation">
                                    <li class="menu-item-has-children" [routerLinkActive]="['active']">
                                        <a class="section-link" routerLink="/home">Home</a>
                                    </li>
                                    <li [class.active]="isFeatureRouteActive()">
                                        <a class="section-link pointer" (click)="navigateWithOffset('feature')">Feature</a>
                                    </li>
                                    <li [class.active]="isRoadMapRouteActive()">
                                        <a class="section-link pointer" (click)="navigateWithOffset('roadMap')">RoadMap</a>
                                    </li>
                                    <li class="menu-item-has-children" [routerLinkActive]="['active']">
                                        <a routerLink="/blog">Blog</a>
                                    </li>
                                    <li [routerLinkActive]="['active']">
                                        <a routerLink="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="header-action">
                                <ul class="list-wrap">
                                    <li class="header-login">
                                        <a class="btn2" href="https://x.com/MirAiToken" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                                    </li>
                                    <li class="header-login">
                                        <a class="btn2" href="https://t.me/mirai_coin_official_channel" target="_blank"><i class="fab fa-telegram-plane"></i></a>
                                    </li>
                                    <li class="header-login">
                                        <a class="btn2" href="https://blog.mirailab.io" target="_blank">Web/APP</a>
                                    </li>
                                </ul>
                            </div>
                            <div (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus" class="mobile-nav-toggler">
                                <i class="fas fa-bars"></i>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- mobile offcanvas menu -->
    <app-mobile-offcanvas />
    <!-- mobile offcanvas menu -->
</header>

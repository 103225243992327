<div [class.mobile-menu-visible]="utilsService.openMobileMenus">
    <!-- header area -->
    <app-header-three />
    <!-- header area -->

    <main>
        <!-- breadcrumb area -->
        <app-breadcrumb title="Contact Us" subTitle="Contact Us" />
        <!-- breadcrumb area -->

        <!-- contact-area -->
        <section class="contact-area pt-140 pb-140">
            <div class="container">
                <div class="contact-info-wrap">
                    <div class="row justify-content-center">
                        <div *ngFor="let item of info_data" class="col-xl-3 col-lg-4 col-md-6">
                            <div class="contact-info-item">
                                <div class="icon">
                                    <i [class]="item.icon"></i>
                                </div>
                                <div class="content">
                                    <h6 class="title">{{ item.title }}</h6>
                                    <p [innerHTML]="item.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact-form-wrap">
                    <div class="row g-0">
                        <div class="col-57 order-0 order-lg-2">
                            <div class="contact-form">
                                <h4 class="title">Send a message</h4>
                                <form id="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-grp">
                                                <input type="text" name="name" placeholder="Enter you name" formControlName="name" />
                                                <span *ngIf="name?.hasError('required') && (name?.touched || formSubmitted)" class="text-danger"> Name is required </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-grp">
                                                <input type="email" name="email" placeholder="Enter you email" formControlName="email" />
                                                <span *ngIf="(email?.hasError('required') && email?.touched) || (email?.hasError('email') && email?.dirty) || (formSubmitted && !email?.value)" class="text-danger"> Email is required and must be a valid email address </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-grp">
                                                <input type="number" name="number" placeholder="Mobile no" formControlName="phone" />
                                                <span *ngIf="phone?.hasError('required') && (phone?.touched || formSubmitted)" class="text-danger">Phone is required </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-grp">
                                                <input type="text" name="company" placeholder="Company" formControlName="company" />
                                                <span *ngIf="company?.hasError('required') && (company?.touched || formSubmitted)" class="text-danger"> Company is required </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-grp">
                                        <textarea name="message" placeholder="Enter you message....." formControlName="message"></textarea>
                                        <span *ngIf="message?.hasError('required') && (message?.touched || formSubmitted)" class="text-danger">Message is required </span>
                                    </div>
                                    <button type="submit" class="btn">Send Message</button>
                                </form>
                                <p class="ajax-response mb-0"></p>
                            </div>
                        </div>
                        <div class="col-43">
                            <div class="contact-map">
                                <iframe src="https://geo-devrel-javascript-samples.web.app/samples/style-array/app/dist/" allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- contact-area-end -->

        <!-- document area -->
        <app-document-area />
        <!-- document area end -->
    </main>

    <!-- footer area -->
    <app-footer-three />
    <!-- footer area -->
</div>

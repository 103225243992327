import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlogApiService } from '../../../services/api/apis/blog.service';
import { PaginationService } from '../../../services/pagination.service';
import { PostBlog } from '../../../types/blog-d-t';

@Component({
    selector: 'app-blog-sidebar',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './blog-sidebar.component.html',
    styleUrls: ['./blog-sidebar.component.css'], // Corregido de styleUrl a styleUrls
})
export class BlogSidebarComponent implements OnInit {
    public blogs: PostBlog[] = [];
    public pageSize: number = 5;
    public paginate: any = {};
    public pageNo: number = 1;
    private topic: string = 'crypto';

    constructor(private blogApiService: BlogApiService, public paginationService: PaginationService) {}

    ngOnInit() {
        this.blogApiService.getPostsBlogApi(this.topic).subscribe((response) => {
            if (Array.isArray(response)) {
                this.blogs = response;
            } else if (typeof response === 'object') {
                this.blogs = [response];
            } else {
                console.error('Unexpected response type:', response);
            }

            this.paginate = this.paginationService.getPager(this.blogs.length, Number(this.pageNo), this.pageSize);
            this.blogs = this.blogs.slice(this.paginate.startIndex, this.paginate.endIndex + 1);

            // Actualizar recent_posts después de obtener los blogs
            this.recent_posts = [...this.blogs].slice(-3);
        });
    }

    public recent_posts: PostBlog[] = [];
}
